<template>
  <ContainerPage>
    <div class="d-flex flex-column flex-lg-row w-100">
      <alertes></alertes>
    </div>
  </ContainerPage>
</template>
<script>
import ContainerPage from "../components/containers/ContainerPage.vue";
import alertes from "@/components/alertes/Alertes.vue";

export default {
  name: "Admin",
  components: {
    ContainerPage,
    alertes
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style></style>
