<template>
  <div class="d-flex flex-column flex-grow-1 mb-3 rounded">
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column flex-md-row">
        <div class style="min-width:300px;">
          <div v-background-3 class="rounded shadow-sm p-3 mb-3 mr-md-3 sticky-top">
            <h5>Catégories</h5>
            <div
              @click="filter = 'all'"
              class="cursor-pointer mb-1 d-flex justify-content-between align-items-center"
              :class="{'text-primary':filter === 'all'}"
            >
              <span>Toutes</span>
              <span class="badge badge-secondary badge-sm ml-1">{{alertesCounter.cptTotal}}</span>
            </div>
            <div
              @click="filter = 'FORMATION'"
              class="cursor-pointer mb-1 d-flex justify-content-between align-items-center"
              :class="{'text-primary':filter === 'FORMATION'}"
            >
              <span>Formations</span>
              <span
                class="badge badge-secondary badge-sm ml-1"
              >{{alertesCounter.cptAlertesFormations}} / {{alertesCounter.cptAlertesFormationsValidee}}</span>
            </div>
            <div
              @click="filter = 'HABILITATION'"
              class="cursor-pointer mb-1 d-flex justify-content-between align-items-center"
              :class="{'text-primary':filter === 'HABILITATION'}"
            >
              <span>Habilitations</span>
              <span
                class="badge badge-secondary badge-sm ml-1"
              >{{alertesCounter.cptAlertesHabilitations}} / {{alertesCounter.cptAlertesHabilitationsValidee}}</span>
            </div>
            <div
              @click="filter = 'VM'"
              class="cursor-pointer mb-1 d-flex justify-content-between align-items-center"
              :class="{'text-primary':filter === 'VM'}"
            >
              <span>Visites médicales</span>
              <span
                class="badge badge-secondary badge-sm ml-1"
              >{{alertesCounter.cptAlertesVms}} / {{alertesCounter.cptAlertesVmsValidee}}</span>
            </div>
            <div
              @click="filter = 'ACCES'"
              class="cursor-pointer mb-1 d-flex justify-content-between align-items-center"
              :class="{'text-primary':filter === 'ACCES'}"
            >
              <span>Accès</span>
              <span
                class="badge badge-secondary badge-sm ml-1"
              >{{alertesCounter.cptAlertesAcces }} / {{alertesCounter.cptAlertesAccesValidee}}</span>
            </div>
            <div
              @click="filter = 'VALID_DOCS'"
              class="cursor-pointer mb-1 d-flex justify-content-between align-items-center"
              :class="{'text-primary':filter === 'VALID_DOCS'}"
            >
              <span>Validité des documents</span>
              <span
                class="badge badge-secondary badge-sm ml-1"
              >{{alertesCounter.cptAlertesValidDocs }} / {{alertesCounter.cptAlertesValidDocsValidee}}</span>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex flex-column">
          <div class="d-flex justify-content-between align-item-center mb-3">
            <h4 class="m-0">Liste des alertes</h4>
            <div>
              <span
                class="mr-3 cursor-pointer"
                @click="showActives = true"
                :class="{'text-primary':showActives}"
              >Alertes</span>
              <span
                class="mr-2 cursor-pointer"
                @click="showActives = false"
                :class="{'text-primary':!showActives}"
              >Alertes validées</span>
            </div>
          </div>
          <div v-if="!loading">
            <div v-if="showActives">
              <div v-for="alerte in alertes" :key="'actives_'+alerte.id">
                <div
                  class="mb-2 p-2 rounded shadow-sm"
                  v-background-3
                  v-if="!alerte.deleted && showItem(alerte)"
                >
                  <div class="d-flex align-items-center">
                    <div class="badge badge-sm badge-danger mr-2 d-none d-md-block">
                      <small>{{ alerte.type }}</small>
                    </div>
                    <div class="d-flex w-100 align-items-center justify-content-between">
                      <div>{{ alerte.text }}</div>
                      <div class="d-flex">
                        <ButtonCircle
                          class="mr-1"
                          name="link"
                          size="18"
                          @click="$router.push(alerte.link)"
                          v-tooltip="'Atteindre cet élément'"
                        ></ButtonCircle>
                        <ButtonCircle
                          name="check"
                          size="18"
                          :loading="loadingCheck"
                          @click="checkAlert(alerte)"
                          v-tooltip="'Valider cet alerte'"
                        ></ButtonCircle>
                      </div>
                    </div>
                  </div>
                </div>
                <!--<alertes-item :alerte="alerte" v-if="alerte.alerte_active === true"></alertes-item>-->
              </div>
            </div>
            <div v-if="!showActives">
              <div v-for="alerte in alertes" :key="'actives_'+alerte.id">
                <div
                  class="mb-2 p-2 rounded shadow-sm"
                  v-background-3
                  v-if="alerte.deleted && showItem(alerte)"
                >
                  <div class="d-flex align-items-center">
                    <div class="badge badge-sm badge-primary mr-2 d-none d-md-block">
                      <small>{{ alerte.type }}</small>
                    </div>
                    <div>{{ alerte.text }}</div>
                  </div>
                </div>
                <!--<alertes-item :alerte="alerte" v-if="alerte.alerte_active === true"></alertes-item>-->
              </div>
            </div>
          </div>
          <div v-if="loading" class="d-flex align-items-center">
            <loader class="m-5"></loader>
            <h2 class="m-0">Chargement...</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  components: { ButtonCircle, Loader },
  data() {
    return {
      newFormation: { alerte_collaborateur_id: 0 },
      feedback: {},
      showActives: true,
      filter: "all",
      loadingCheck: false,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      _getAlertes: "alertes/getAlertes",
      _checkAlert: "alertes/checkAlert"
    }),
    getAlertes: function() {
      this.feedback = {};
      this.loading = true;
      this._getAlertes()
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    checkAlert: function(alert) {
      this.feedback = {};
      this.loadingCheck = true;
      this._checkAlert(alert)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingCheck = false));
    },

    showItem: function(item) {
      if (this.filter === "all") {
        return true;
      } else {
        if (item.type === this.filter) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.getAlertes();
  },
  computed: {
    ...mapMultiRowFields("alertes", ["alertes"]),
    ...mapGetters({
      data: "alertes/data"
    }),
    alertesCounter() {
      let data = this.alertes;
      let cptAlertesFormations = 0;
      let cptAlertesFormationsValidee = 0;
      let cptAlertesHabilitations = 0;
      let cptAlertesHabilitationsValidee = 0;
      let cptAlertesVms = 0;
      let cptAlertesVmsValidee = 0;
      let cptAlertesAcces = 0;
      let cptAlertesAccesValidee = 0;
      let cptAlertesValidDocs = 0;
      let cptAlertesValidDocsValidee = 0;

      data.forEach(alert => {
        if (alert.type == "FORMATION" && alert.deleted == 0)
          cptAlertesFormations++;
        if (alert.type == "FORMATION" && alert.deleted == 1)
          cptAlertesFormationsValidee++;
        if (alert.type == "HABILITATION" && alert.deleted == 0)
          cptAlertesHabilitations++;
        if (alert.type == "HABILITATION" && alert.deleted == 1)
          cptAlertesHabilitationsValidee++;
        if (alert.type == "VM" && alert.deleted == 0) cptAlertesVms++;
        if (alert.type == "VM" && alert.deleted == 1) cptAlertesVmsValidee++;
        if (alert.type == "ACCES" && alert.deleted == 0) cptAlertesAcces++;
        if (alert.type == "ACCES" && alert.deleted == 1)
          cptAlertesAccesValidee++;
        if (alert.type == "VALID_DOCS" && alert.deleted == 0)
          cptAlertesValidDocs++;
        if (alert.type == "VALID_DOCS" && alert.deleted == 1)
          cptAlertesValidDocsValidee++;
      });

      return {
        cptTotal:
          cptAlertesFormations +
          cptAlertesHabilitations +
          cptAlertesVms +
          cptAlertesAcces,
        cptAlertesFormations: cptAlertesFormations,
        cptAlertesFormationsValidee: cptAlertesFormationsValidee,
        cptAlertesHabilitations: cptAlertesHabilitations,
        cptAlertesHabilitationsValidee: cptAlertesHabilitationsValidee,
        cptAlertesVms: cptAlertesVms,
        cptAlertesVmsValidee: cptAlertesVmsValidee,
        cptAlertesAcces: cptAlertesAcces,
        cptAlertesAccesValidee: cptAlertesAccesValidee,
        cptAlertesValidDocs: cptAlertesValidDocs,
        cptAlertesValidDocsValidee: cptAlertesValidDocsValidee
      };
    }
  }
};
</script>
<style lang="css">
</style>